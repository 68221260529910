import { debounceTime, takeUntil } from 'rxjs';
import { filter, distinctUntilChanged, switchMap, tap } from 'rxjs/operators';
function typeahead(
/**
 * Функция, которая будет вызываться для загрузки результатов.
 * @param searchTerm
 */
loadFn,
/**
 * Разрешить ли считать пустую строку допустимым условием поиска.
 * @default true
 */
allowEmptyString = true,
/**
 * Минимальная длина поискового запроса
 * @default 3
 */
minLength = 3,
/**
 * Время между нажатиями клавиш до отправки запроса
 * @default 600
 */
debounce = 600) {
  let shouldAllowSameValue = false;
  return source => {
    return source.pipe(debounceTime(debounce), filter(value => typeof value === 'string'), filter(value => {
      if (value === '') return allowEmptyString;
      return value.length >= minLength;
    }), distinctUntilChanged((prev, current) => {
      if (shouldAllowSameValue) {
        shouldAllowSameValue = false;
        return false;
      }
      return prev === current;
    }), switchMap(searchTerm => loadFn(searchTerm).pipe(takeUntil(source.pipe(tap(() => {
      shouldAllowSameValue = true;
    }))))));
  };
}

/**
 * Generated bundle index. Do not edit.
 */

export { typeahead };
