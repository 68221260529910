function mgArrayRemoveIdx(array, index) {
  return array.slice(0, Math.max(index, 0)).concat(array.slice(Math.max(index + 1, 0)));
}

/**
 * Adds 'px' to the number and turns it into a string
 */
function mgPx(value) {
  return `${value}px`;
}
function mgOverrideOptions(override, fallback) {
  return (directive, options) => {
    const result = directive || {
      ...(options || fallback)
    };
    Object.keys(override).forEach(key => {
      // Update directive props with new defaults before inputs are processed
      result[key] = override[key];
    });
    return result;
  };
}

/**
 * Generated bundle index. Do not edit.
 */

export { mgArrayRemoveIdx, mgOverrideOptions, mgPx };
