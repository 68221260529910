import { fromEvent, pipe, EMPTY } from 'rxjs';
import { switchMap } from 'rxjs/operators';
function mgTypedFromEvent(target, event, options = {}) {
  return fromEvent(target, event, options);
}
function mgIfMap(project, predicate = Boolean) {
  return pipe(switchMap(value => predicate(value) ? project(value) : EMPTY));
}

/**
 * Generated bundle index. Do not edit.
 */

export { mgIfMap, mgTypedFromEvent };
