import { InjectionToken, inject, InjectFlags, PLATFORM_ID } from '@angular/core';
import { ɵAnimationEngine } from '@angular/animations/browser';
import { BehaviorSubject, timer } from 'rxjs';
import { switchMap, map, startWith, share } from 'rxjs/operators';
import { WINDOW } from '@ng-web-apis/common';
import { EMPTY_CLIENT_RECT } from '@marketguru/ui-kit-components/constants';
import { isPlatformBrowser } from '@angular/common';
const MG_VALUE_ACCESSOR = new InjectionToken(`[MG_VALUE_ACCESSOR]: Buffer token to pass NG_VALUE_ACCESSOR to a different Injector`);

/**
 * Element currently being removed by AnimationEngine
 */
const MG_REMOVED_ELEMENT = new InjectionToken(`[MG_REMOVED_ELEMENT]`, {
  factory: () => {
    const stub = {
      onRemovalComplete: () => {}
    };
    const element$ = new BehaviorSubject(null);
    const engine = inject(ɵAnimationEngine, InjectFlags.Optional) || stub;
    const {
      onRemovalComplete = stub.onRemovalComplete
    } = engine;
    engine.onRemovalComplete = (element, context) => {
      element$.next(element);
      onRemovalComplete.call(engine, element, context);
    };
    return element$.pipe(switchMap(element => timer(0).pipe(map(() => null), startWith(element))), share());
  }
});

/**
 * Webkit browser engine detection
 */
const MG_IS_WEBKIT = new InjectionToken(`[MG_IS_WEBKIT]`, {
  factory: () => !!inject(WINDOW)?.webkitConvertPointFromNodeToPage
});

/**
 * Viewport accessor
 */
const MG_VIEWPORT = new InjectionToken(`[MG_VIEWPORT]`, {
  factory: () => {
    const win = inject(WINDOW);
    return {
      type: `viewport`,
      getBoundingClientRect() {
        return {
          ...EMPTY_CLIENT_RECT,
          top: 0,
          left: 0,
          right: win.innerWidth,
          bottom: win.innerHeight,
          width: win.innerWidth,
          height: win.innerHeight
        };
      }
    };
  }
});
function mgAsViewport(useExisting) {
  return {
    provide: MG_VIEWPORT,
    useExisting
  };
}
const MG_RANGE = new InjectionToken(`[MG_RANGE]`, {
  factory: () => isPlatformBrowser(inject(PLATFORM_ID)) ? new Range() : {}
});

/**
 * Generated bundle index. Do not edit.
 */

export { MG_IS_WEBKIT, MG_RANGE, MG_REMOVED_ELEMENT, MG_VALUE_ACCESSOR, MG_VIEWPORT, mgAsViewport };
