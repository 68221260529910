import { Observable, pipe } from 'rxjs';
function mgZonefull(ngZone) {
  return source => new Observable(subscriber => source.subscribe({
    next: value => ngZone.run(() => subscriber.next(value)),
    error: error => ngZone.run(() => subscriber.error(error)),
    complete: () => ngZone.run(() => subscriber.complete())
  }));
}
function mgZonefree(ngZone) {
  return source => new Observable(subscriber => ngZone.runOutsideAngular(() => source.subscribe(subscriber)));
}
function mgZoneOptimized(ngZone) {
  return pipe(mgZonefree(ngZone), mgZonefull(ngZone));
}

/**
 * Generated bundle index. Do not edit.
 */

export { mgZoneOptimized, mgZonefree, mgZonefull };
