import { CHAR_NO_BREAK_SPACE, CHAR_ZERO_WIDTH_SPACE } from '@marketguru/ui-kit-components/constants';
import { mgIsPresent } from '@marketguru/ui-kit-components/utils/miscellaneous';
import { mgClamp } from '@marketguru/ui-kit-components/utils/math';

/**
 * Gets actual target from open Shadow DOM if event happened within it
 */
function mgGetActualTarget(event) {
  return event.composedPath()[0];
}
function mgGetDocumentOrShadowRoot(node) {
  return `getRootNode` in node && node.isConnected ? node.getRootNode() : node.ownerDocument;
}
function mgIsInput(element) {
  return element.matches(`input`);
}
function mgIsTextarea(element) {
  return element.matches(`textarea`);
}
function mgIsTextfield(element) {
  return mgIsInput(element) || mgIsTextarea(element);
}
function mgIsElement(node) {
  return !!node && `nodeType` in node && node.nodeType === Node.ELEMENT_NODE;
}
function mgIsHTMLElement(node) {
  return node instanceof HTMLElement;
}
function mgIsTextNode(node) {
  return node.nodeType === Node.TEXT_NODE;
}
function mgCheckFixedPosition(element) {
  if (!element || typeof getComputedStyle === `undefined`) {
    return false;
  }
  const style = getComputedStyle(element);
  return style.getPropertyValue(`position`) === `fixed` || mgCheckFixedPosition(element.parentElement);
}
function mgPointToClientRect(x = 0, y = 0) {
  const rect = {
    x,
    y,
    left: x,
    right: x,
    top: y,
    bottom: y,
    width: 0,
    height: 0
  };
  return {
    ...rect,
    toJSON() {
      return rect;
    }
  };
}

/**
 * Creates a cloned range with its boundaries set at word boundaries
 *
 * @param currentRange a range to clone
 * @return modified range
 */
// eslint-disable-next-line max-statements
function mgGetWordRange(currentRange) {
  const range = currentRange.cloneRange();
  const {
    startContainer,
    startOffset,
    endContainer,
    endOffset
  } = range;
  const {
    ownerDocument
  } = startContainer;
  if (!ownerDocument) {
    return range;
  }
  const treeWalker = ownerDocument.createTreeWalker(ownerDocument.body, NodeFilter.SHOW_TEXT);
  treeWalker.currentNode = startContainer;
  do {
    const container = treeWalker.currentNode;
    const textContent = container.textContent || ``;
    const content = container === startContainer ? textContent.slice(0, Math.max(0, startOffset + 1)) : textContent;
    const offset = Math.max(content.lastIndexOf(` `), content.lastIndexOf(CHAR_NO_BREAK_SPACE), content.lastIndexOf(CHAR_ZERO_WIDTH_SPACE)) + 1;
    range.setStart(container, 0);
    if (offset) {
      range.setStart(container, offset);
      break;
    }
  } while (treeWalker.previousNode());
  treeWalker.currentNode = endContainer;
  do {
    const container = treeWalker.currentNode;
    const textContent = container.textContent || ``;
    const content = container === endContainer ? textContent.slice(endOffset + 1) : textContent;
    const offset = [content.indexOf(` `), content.indexOf(CHAR_NO_BREAK_SPACE), content.indexOf(CHAR_ZERO_WIDTH_SPACE)].reduce((result, item) => result === -1 || item === -1 ? Math.max(result, item) : Math.min(result, item), -1);
    range.setEnd(container, textContent.length);
    if (offset !== -1) {
      range.setEnd(container, offset + textContent.length - content.length);
      break;
    }
  } while (treeWalker.nextNode());
  return range;
}

/**
 * Функция проверки наличия класса у элемента
 * @param elementRef - elementRef проверяемого элемента
 * @param className - название класса
 */
const mgHasClass = (elementRef, className) => {
  return elementRef.nativeElement.classList.contains(className);
};
function mgIsElementEditable(element) {
  return mgIsTextfield(element) && !element.readOnly || element.isContentEditable;
}

/**
 * Returns array of Elements covering edges of given element or null if at least one edge middle point is visible
 *
 * CAUTION: Empty array means element if offscreen i.e. covered by no elements, rather than not covered
 * TODO: v4.0 change function signature to
 * ```ts
 * function mgGetElementObscures(element: Element): readonly [Element, Element, Element, Element] | [] | null
 * ```
 */
function mgGetElementObscures(element) {
  const {
    ownerDocument
  } = element;
  if (!ownerDocument?.defaultView || !element.getBoundingClientRect) {
    return null;
  }
  const {
    innerWidth,
    innerHeight
  } = ownerDocument.defaultView;
  const doc = mgGetDocumentOrShadowRoot(element);
  const rect = element.getBoundingClientRect();
  if (rect.width === 0 && rect.height === 0) {
    return null;
  }
  const left = mgClamp(Math.round(rect.left) + 2, 0, innerWidth);
  const top = mgClamp(Math.round(rect.top) + 2, 0, innerHeight);
  const right = mgClamp(Math.round(rect.right) - 2, 0, innerWidth);
  const bottom = mgClamp(Math.round(rect.bottom) - 2, 0, innerHeight);
  const horizontalMiddle = mgClamp(Math.round(rect.left + rect.width / 2), 0, innerWidth);
  const verticalMiddle = mgClamp(Math.round(rect.top + rect.height / 2), 0, innerHeight);
  const elements = [doc.elementFromPoint(horizontalMiddle, top), doc.elementFromPoint(horizontalMiddle, bottom), doc.elementFromPoint(left, verticalMiddle), doc.elementFromPoint(right, verticalMiddle)];
  const nonNull = elements.filter(mgIsPresent);
  if (!nonNull.length) {
    return nonNull;
  }
  const filtered = nonNull.filter(el => !element.contains(el));
  return filtered.length === 4 ? filtered : null;
}

/**
 * Generated bundle index. Do not edit.
 */

export { mgCheckFixedPosition, mgGetActualTarget, mgGetDocumentOrShadowRoot, mgGetElementObscures, mgGetWordRange, mgHasClass, mgIsElement, mgIsElementEditable, mgIsHTMLElement, mgIsInput, mgIsTextNode, mgIsTextarea, mgIsTextfield, mgPointToClientRect };
