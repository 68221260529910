import { DOCUMENT } from '@angular/common';
import { InjectionToken, inject } from '@angular/core';
import { WINDOW } from '@ng-web-apis/common';
import { merge, of } from 'rxjs';
import { map, filter, switchMap, distinctUntilChanged, share } from 'rxjs/operators';
import { mgTypedFromEvent } from '@marketguru/ui-kit-components/observables';
import { mgGetActualTarget, mgGetDocumentOrShadowRoot } from '@marketguru/ui-kit-components/utils/dom';
import { MG_REMOVED_ELEMENT } from '@marketguru/ui-kit-components/tokens';

/**
 * Active element on the document for ActiveZone
 */
const MG_ACTIVE_ELEMENT = new InjectionToken(`[MG_ACTIVE_ELEMENT]`, {
  factory: () => {
    const removedElement$ = inject(MG_REMOVED_ELEMENT);
    const win = inject(WINDOW);
    const doc = inject(DOCUMENT);
    const focusout$ = mgTypedFromEvent(win, `focusout`);
    const focusin$ = mgTypedFromEvent(win, `focusin`);
    const blur$ = mgTypedFromEvent(win, `blur`);
    const mousedown$ = mgTypedFromEvent(win, `mousedown`);
    const mouseup$ = mgTypedFromEvent(win, `mouseup`);
    return merge(
    // focusout$.pipe(
    //   takeUntil(mousedown$),
    //   repeatWhen(() => mouseup$),
    //   withLatestFrom(removedElement$),
    //   filter(([event, removedElement]) => {
    //     console.log('=>(active-element.ts:96) event', event);
    //     console.log(
    //       '=>(active-element.ts:97) removedElement',
    //       removedElement
    //     );
    //
    //     return isValidFocusout(mgGetActualTarget(event), removedElement);
    //   }),
    //   map(([e]) => {
    //     console.log('=>(active-element.ts:94) e', e);
    //
    //     return e?.relatedTarget;
    //   }),
    //   tap((el) => console.log('focusOUT', el))
    // ),
    blur$.pipe(map(() => doc.activeElement), filter(element => !!element?.matches(`iframe`))),
    // Отключенно из-за cdk dialog и его афтофокуса
    // focusin$.pipe(
    //   switchMap((event) => {
    //     const target = mgGetActualTarget(event);
    //     const root = mgGetDocumentOrShadowRoot(target) as Document;
    //
    //     return root === doc
    //       ? of(target)
    //       : shadowRootActiveElement(root).pipe(startWith(target));
    //   })
    // ),
    mousedown$.pipe(switchMap(event => {
      const actualTargetInCurrentTime = mgGetActualTarget(event);
      return of(actualTargetInCurrentTime);
      // Отключенно из-за cdk dialog и его афтофокуса
      // return !doc.activeElement || doc.activeElement === doc.body
      //   ? of(actualTargetInCurrentTime)
      //   : focusout$.pipe(
      //       tap(() =>
      //         console.log(
      //           'actualTargetInCurrentTime OUT',
      //           actualTargetInCurrentTime
      //         )
      //       ),
      //       take(1),
      //       map(
      //         /**
      //          * Do not use `map(() => tuiGetActualTarget(event))`
      //          * because we have different result in runtime
      //          */
      //         () => actualTargetInCurrentTime
      //       ),
      //       takeUntil(timer(0))
      //     );
    }))).pipe(distinctUntilChanged(), share());
  }
});
// Checks if focusout event should be considered leaving active zone
function isValidFocusout(target, removedElement = null) {
  return (
    // Not due to switching tabs/going to DevTools
    mgGetDocumentOrShadowRoot(target).activeElement !== target &&
    // Not due to button/input becoming disabled or under disabled fieldset
    !target.matches(`:disabled`) &&
    // Not due to element being removed from DOM
    !removedElement?.contains(target)
  );
}
function shadowRootActiveElement(root) {
  return merge(mgTypedFromEvent(root, `focusin`).pipe(map(({
    target
  }) => target)), mgTypedFromEvent(root, `focusout`).pipe(filter(({
    target,
    relatedTarget
  }) => !!relatedTarget && isValidFocusout(target)), map(({
    relatedTarget
  }) => relatedTarget)));
}

/**
 * Generated bundle index. Do not edit.
 */

export { MG_ACTIVE_ELEMENT };
