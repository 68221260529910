import { InjectionToken, Optional, SkipSelf } from '@angular/core';
function mgCreateToken(defaults) {
  return new InjectionToken(``, {
    factory: () => defaults
  });
}
function mgProvideOptions(provide, options, fallback) {
  return {
    provide,
    deps: [[new Optional(), new SkipSelf(), provide]],
    useFactory: parent => ({
      ...(parent || fallback),
      ...options
    })
  };
}

/**
 * Generated bundle index. Do not edit.
 */

export { mgCreateToken, mgProvideOptions };
