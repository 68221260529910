import { mgIsHTMLElement } from '@marketguru/ui-kit-components/utils/dom';

/**
 * Returns current active element, including shadow dom
 *
 * @return element or null
 */
function mgGetNativeFocused({
  activeElement
}) {
  if (!activeElement?.shadowRoot) {
    return activeElement;
  }
  let element = activeElement.shadowRoot.activeElement;
  while (element?.shadowRoot) {
    element = element.shadowRoot.activeElement;
  }
  return element;
}

/**
 * Checks if element is focused.
 *
 * Could return true even after blur since element remains focused if you switch away from a browser tab.
 *
 * @param node or null (as a common return value of DOM nodes walking)
 * @return true if focused
 */
function mgIsNativeFocused(node) {
  return !!node?.ownerDocument && mgGetNativeFocused(node.ownerDocument) === node;
}

/**
 * Checks for signs that element can be focused with keyboard. tabIndex above 0 is ignored to
 * only target natural focus order. Not checking the possibility of an element to
 * be focused, for example element can have display: none applied to it or any other
 * circumstances could prevent actual focus.
 */
function mgIsNativeKeyboardFocusable(element) {
  if (element.hasAttribute(`disabled`) || element.getAttribute(`tabIndex`) === `-1`) {
    return false;
  }
  if (mgIsHTMLElement(element) && element.isContentEditable || element.getAttribute(`tabIndex`) === `0`) {
    return true;
  }
  switch (element.tagName) {
    case `BUTTON`:
    case `SELECT`:
    case `TEXTAREA`:
      return true;
    case `VIDEO`:
    case `AUDIO`:
      return element.hasAttribute(`controls`);
    case `INPUT`:
      return element.getAttribute(`type`) !== `hidden`;
    case `A`:
    case `LINK`:
      return element.hasAttribute(`href`);
    default:
      return false;
  }
}

/**
 * Checks if focused element is within given element.
 *
 * @param node
 * @return true if focused node is contained within element
 */
function mgIsNativeFocusedIn(node) {
  // !node.contains - check for IE11
  if (!node.ownerDocument || !node.contains) {
    return false;
  }
  const nativeFocused = mgGetNativeFocused(node.ownerDocument);
  return nativeFocused !== null && node.contains(nativeFocused);
}
function mgIsNativeMouseFocusable(element) {
  return !element.hasAttribute(`disabled`) && (element.getAttribute(`tabIndex`) === `-1` || mgIsNativeKeyboardFocusable(element));
}

/**
 * @description:
 * Finds the closest element that can be focused with a keyboard or mouse in theory
 */
function mgGetClosestFocusable({
  initial,
  root,
  previous = false,
  keyboard = true
}) {
  if (!root.ownerDocument) {
    return null;
  }
  const check = keyboard ? mgIsNativeKeyboardFocusable : mgIsNativeMouseFocusable;
  const treeWalker = root.ownerDocument.createTreeWalker(root, NodeFilter.SHOW_ELEMENT);
  treeWalker.currentNode = initial;
  while (previous ? treeWalker.previousNode() : treeWalker.nextNode()) {
    if (mgIsHTMLElement(treeWalker.currentNode)) {
      initial = treeWalker.currentNode;
    }
    if (mgIsHTMLElement(initial) && check(initial)) {
      return initial;
    }
  }
  return null;
}

/**
 * Generated bundle index. Do not edit.
 */

export { mgGetClosestFocusable, mgGetNativeFocused, mgIsNativeFocused, mgIsNativeFocusedIn, mgIsNativeKeyboardFocusable, mgIsNativeMouseFocusable };
