/**
 * Clamps a value between two inclusive limits
 *
 * @param value
 * @param min lower limit
 * @param max upper limit
 */
function mgClamp(value, min, max) {
  return Math.min(max, Math.max(min, value));
}

/**
 * Generated bundle index. Do not edit.
 */

export { mgClamp };
