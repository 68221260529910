import { rectToClientRect as t, computePosition as e } from "@floating-ui/core";
export { arrow, autoPlacement, detectOverflow, flip, hide, inline, limitShift, offset, shift, size } from "@floating-ui/core";
function n(t) {
  var e;
  return (null == (e = t.ownerDocument) ? void 0 : e.defaultView) || window;
}
function o(t) {
  return n(t).getComputedStyle(t);
}
const i = Math.min,
  r = Math.max,
  l = Math.round;
function c(t) {
  const e = o(t);
  let n = parseFloat(e.width),
    i = parseFloat(e.height);
  const r = t.offsetWidth,
    c = t.offsetHeight,
    s = l(n) !== r || l(i) !== c;
  return s && (n = r, i = c), {
    width: n,
    height: i,
    fallback: s
  };
}
function s(t) {
  return h(t) ? (t.nodeName || "").toLowerCase() : "";
}
let f;
function u() {
  if (f) return f;
  const t = navigator.userAgentData;
  return t && Array.isArray(t.brands) ? (f = t.brands.map(t => t.brand + "/" + t.version).join(" "), f) : navigator.userAgent;
}
function a(t) {
  return t instanceof n(t).HTMLElement;
}
function d(t) {
  return t instanceof n(t).Element;
}
function h(t) {
  return t instanceof n(t).Node;
}
function p(t) {
  if ("undefined" == typeof ShadowRoot) return !1;
  return t instanceof n(t).ShadowRoot || t instanceof ShadowRoot;
}
function g(t) {
  const {
    overflow: e,
    overflowX: n,
    overflowY: i,
    display: r
  } = o(t);
  return /auto|scroll|overlay|hidden|clip/.test(e + i + n) && !["inline", "contents"].includes(r);
}
function m(t) {
  return ["table", "td", "th"].includes(s(t));
}
function y(t) {
  const e = /firefox/i.test(u()),
    n = o(t),
    i = n.backdropFilter || n.WebkitBackdropFilter;
  return "none" !== n.transform || "none" !== n.perspective || !!i && "none" !== i || e && "filter" === n.willChange || e && !!n.filter && "none" !== n.filter || ["transform", "perspective"].some(t => n.willChange.includes(t)) || ["paint", "layout", "strict", "content"].some(t => {
    const e = n.contain;
    return null != e && e.includes(t);
  });
}
function x() {
  return /^((?!chrome|android).)*safari/i.test(u());
}
function w(t) {
  return ["html", "body", "#document"].includes(s(t));
}
function v(t) {
  return d(t) ? t : t.contextElement;
}
const b = {
  x: 1,
  y: 1
};
function L(t) {
  const e = v(t);
  if (!a(e)) return b;
  const n = e.getBoundingClientRect(),
    {
      width: o,
      height: i,
      fallback: r
    } = c(e);
  let s = (r ? l(n.width) : n.width) / o,
    f = (r ? l(n.height) : n.height) / i;
  return s && Number.isFinite(s) || (s = 1), f && Number.isFinite(f) || (f = 1), {
    x: s,
    y: f
  };
}
function E(e, o, i, r) {
  var l, c;
  void 0 === o && (o = !1), void 0 === i && (i = !1);
  const s = e.getBoundingClientRect(),
    f = v(e);
  let u = b;
  o && (r ? d(r) && (u = L(r)) : u = L(e));
  const a = f ? n(f) : window,
    h = x() && i;
  let p = (s.left + (h && (null == (l = a.visualViewport) ? void 0 : l.offsetLeft) || 0)) / u.x,
    g = (s.top + (h && (null == (c = a.visualViewport) ? void 0 : c.offsetTop) || 0)) / u.y,
    m = s.width / u.x,
    y = s.height / u.y;
  if (f) {
    const t = n(f),
      e = r && d(r) ? n(r) : r;
    let o = t.frameElement;
    for (; o && r && e !== t;) {
      const t = L(o),
        e = o.getBoundingClientRect(),
        i = getComputedStyle(o);
      e.x += (o.clientLeft + parseFloat(i.paddingLeft)) * t.x, e.y += (o.clientTop + parseFloat(i.paddingTop)) * t.y, p *= t.x, g *= t.y, m *= t.x, y *= t.y, p += e.x, g += e.y, o = n(o).frameElement;
    }
  }
  return t({
    width: m,
    height: y,
    x: p,
    y: g
  });
}
function R(t) {
  return ((h(t) ? t.ownerDocument : t.document) || window.document).documentElement;
}
function T(t) {
  return d(t) ? {
    scrollLeft: t.scrollLeft,
    scrollTop: t.scrollTop
  } : {
    scrollLeft: t.pageXOffset,
    scrollTop: t.pageYOffset
  };
}
function C(t) {
  return E(R(t)).left + T(t).scrollLeft;
}
function F(t) {
  if ("html" === s(t)) return t;
  const e = t.assignedSlot || t.parentNode || p(t) && t.host || R(t);
  return p(e) ? e.host : e;
}
function W(t) {
  const e = F(t);
  return w(e) ? e.ownerDocument.body : a(e) && g(e) ? e : W(e);
}
function D(t, e) {
  var o;
  void 0 === e && (e = []);
  const i = W(t),
    r = i === (null == (o = t.ownerDocument) ? void 0 : o.body),
    l = n(i);
  return r ? e.concat(l, l.visualViewport || [], g(i) ? i : []) : e.concat(i, D(i));
}
function S(e, i, l) {
  let c;
  if ("viewport" === i) c = function (t, e) {
    const o = n(t),
      i = R(t),
      r = o.visualViewport;
    let l = i.clientWidth,
      c = i.clientHeight,
      s = 0,
      f = 0;
    if (r) {
      l = r.width, c = r.height;
      const t = x();
      (!t || t && "fixed" === e) && (s = r.offsetLeft, f = r.offsetTop);
    }
    return {
      width: l,
      height: c,
      x: s,
      y: f
    };
  }(e, l);else if ("document" === i) c = function (t) {
    const e = R(t),
      n = T(t),
      i = t.ownerDocument.body,
      l = r(e.scrollWidth, e.clientWidth, i.scrollWidth, i.clientWidth),
      c = r(e.scrollHeight, e.clientHeight, i.scrollHeight, i.clientHeight);
    let s = -n.scrollLeft + C(t);
    const f = -n.scrollTop;
    return "rtl" === o(i).direction && (s += r(e.clientWidth, i.clientWidth) - l), {
      width: l,
      height: c,
      x: s,
      y: f
    };
  }(R(e));else if (d(i)) c = function (t, e) {
    const n = E(t, !0, "fixed" === e),
      o = n.top + t.clientTop,
      i = n.left + t.clientLeft,
      r = a(t) ? L(t) : {
        x: 1,
        y: 1
      };
    return {
      width: t.clientWidth * r.x,
      height: t.clientHeight * r.y,
      x: i * r.x,
      y: o * r.y
    };
  }(i, l);else {
    const t = {
      ...i
    };
    if (x()) {
      var s, f;
      const o = n(e);
      t.x -= (null == (s = o.visualViewport) ? void 0 : s.offsetLeft) || 0, t.y -= (null == (f = o.visualViewport) ? void 0 : f.offsetTop) || 0;
    }
    c = t;
  }
  return t(c);
}
function A(t, e) {
  return a(t) && "fixed" !== o(t).position ? e ? e(t) : t.offsetParent : null;
}
function H(t, e) {
  const i = n(t);
  if (!a(t)) return i;
  let r = A(t, e);
  for (; r && m(r) && "static" === o(r).position;) r = A(r, e);
  return r && ("html" === s(r) || "body" === s(r) && "static" === o(r).position && !y(r)) ? i : r || function (t) {
    let e = F(t);
    for (; a(e) && !w(e);) {
      if (y(e)) return e;
      e = F(e);
    }
    return null;
  }(t) || i;
}
function V(t, e, n) {
  const o = a(e),
    i = R(e),
    r = E(t, !0, "fixed" === n, e);
  let l = {
    scrollLeft: 0,
    scrollTop: 0
  };
  const c = {
    x: 0,
    y: 0
  };
  if (o || !o && "fixed" !== n) if (("body" !== s(e) || g(i)) && (l = T(e)), a(e)) {
    const t = E(e, !0);
    c.x = t.x + e.clientLeft, c.y = t.y + e.clientTop;
  } else i && (c.x = C(i));
  return {
    x: r.left + l.scrollLeft - c.x,
    y: r.top + l.scrollTop - c.y,
    width: r.width,
    height: r.height
  };
}
const O = {
  getClippingRect: function (t) {
    let {
      element: e,
      boundary: n,
      rootBoundary: l,
      strategy: c
    } = t;
    const f = "clippingAncestors" === n ? function (t, e) {
        const n = e.get(t);
        if (n) return n;
        let i = D(t).filter(t => d(t) && "body" !== s(t)),
          r = null;
        const l = "fixed" === o(t).position;
        let c = l ? F(t) : t;
        for (; d(c) && !w(c);) {
          const t = o(c),
            e = y(c);
          "fixed" === t.position ? r = null : (l ? e || r : e || "static" !== t.position || !r || !["absolute", "fixed"].includes(r.position)) ? r = t : i = i.filter(t => t !== c), c = F(c);
        }
        return e.set(t, i), i;
      }(e, this._c) : [].concat(n),
      u = [...f, l],
      a = u[0],
      h = u.reduce((t, n) => {
        const o = S(e, n, c);
        return t.top = r(o.top, t.top), t.right = i(o.right, t.right), t.bottom = i(o.bottom, t.bottom), t.left = r(o.left, t.left), t;
      }, S(e, a, c));
    return {
      width: h.right - h.left,
      height: h.bottom - h.top,
      x: h.left,
      y: h.top
    };
  },
  convertOffsetParentRelativeRectToViewportRelativeRect: function (t) {
    let {
      rect: e,
      offsetParent: n,
      strategy: o
    } = t;
    const i = a(n),
      r = R(n);
    if (n === r) return e;
    let l = {
        scrollLeft: 0,
        scrollTop: 0
      },
      c = {
        x: 1,
        y: 1
      };
    const f = {
      x: 0,
      y: 0
    };
    if ((i || !i && "fixed" !== o) && (("body" !== s(n) || g(r)) && (l = T(n)), a(n))) {
      const t = E(n);
      c = L(n), f.x = t.x + n.clientLeft, f.y = t.y + n.clientTop;
    }
    return {
      width: e.width * c.x,
      height: e.height * c.y,
      x: e.x * c.x - l.scrollLeft * c.x + f.x,
      y: e.y * c.y - l.scrollTop * c.y + f.y
    };
  },
  isElement: d,
  getDimensions: function (t) {
    return a(t) ? c(t) : t.getBoundingClientRect();
  },
  getOffsetParent: H,
  getDocumentElement: R,
  getScale: L,
  async getElementRects(t) {
    let {
      reference: e,
      floating: n,
      strategy: o
    } = t;
    const i = this.getOffsetParent || H,
      r = this.getDimensions;
    return {
      reference: V(e, await i(n), o),
      floating: {
        x: 0,
        y: 0,
        ...(await r(n))
      }
    };
  },
  getClientRects: t => Array.from(t.getClientRects()),
  isRTL: t => "rtl" === o(t).direction
};
function P(t, e, n, o) {
  void 0 === o && (o = {});
  const {
      ancestorScroll: i = !0,
      ancestorResize: r = !0,
      elementResize: l = !0,
      animationFrame: c = !1
    } = o,
    s = i && !c,
    f = s || r ? [...(d(t) ? D(t) : t.contextElement ? D(t.contextElement) : []), ...D(e)] : [];
  f.forEach(t => {
    s && t.addEventListener("scroll", n, {
      passive: !0
    }), r && t.addEventListener("resize", n);
  });
  let u,
    a = null;
  if (l) {
    let o = !0;
    a = new ResizeObserver(() => {
      o || n(), o = !1;
    }), d(t) && !c && a.observe(t), d(t) || !t.contextElement || c || a.observe(t.contextElement), a.observe(e);
  }
  let h = c ? E(t) : null;
  return c && function e() {
    const o = E(t);
    !h || o.x === h.x && o.y === h.y && o.width === h.width && o.height === h.height || n();
    h = o, u = requestAnimationFrame(e);
  }(), n(), () => {
    var t;
    f.forEach(t => {
      s && t.removeEventListener("scroll", n), r && t.removeEventListener("resize", n);
    }), null == (t = a) || t.disconnect(), a = null, c && cancelAnimationFrame(u);
  };
}
const z = (t, n, o) => {
  const i = new Map(),
    r = {
      platform: O,
      ...o
    },
    l = {
      ...r.platform,
      _c: i
    };
  return e(t, n, {
    ...r,
    platform: l
  });
};
export { P as autoUpdate, z as computePosition, D as getOverflowAncestors, O as platform };