import { SkipSelf } from '@angular/core';
class MgRectAccessor {}
function mgRectAccessorFor(type, fallback) {
  return {
    provide: MgRectAccessor,
    deps: [[new SkipSelf(), MgRectAccessor], fallback],
    useFactory: mgFallbackRectAccessor(type)
  };
}
function mgFallbackRectAccessor(type) {
  return (accessors, fallback) => {
    return accessors.find(accessor => accessor !== fallback && accessor.type === type) || fallback;
  };
}
function mgAsRectAccessor(useExisting) {
  return {
    provide: MgRectAccessor,
    multi: true,
    useExisting
  };
}
class MgPositionAccessor {}
function mgPositionAccessorFor(type) {
  return {
    provide: MgPositionAccessor,
    deps: [[new SkipSelf(), MgPositionAccessor]],
    useFactory: accessors => accessors.find(accessor => accessor.type === type)
  };
}
function mgAsPositionAccessor(useExisting) {
  return {
    provide: MgPositionAccessor,
    multi: true,
    useExisting
  };
}

/**
 * Generated bundle index. Do not edit.
 */

export { MgPositionAccessor, MgRectAccessor, mgAsPositionAccessor, mgAsRectAccessor, mgFallbackRectAccessor, mgPositionAccessorFor, mgRectAccessorFor };
